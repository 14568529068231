/**
 * Note: this file only serves as entry point and imports all required functionality from modules.
 * These modules are responsible to check whether they are applicable on the current site and only then load their business logic.
 * This way, we can have the best of both worlds, tree-shaking and dynamic imports.
 *
 * When writing modules, make sure to 'register' them in the corresponding index.js and reference them only over this file.
 *
 * If a module is completely not used on a project, comment the corresponding function calls here.
 */

// breakpoint on which headroom should be active
// NOTE: has to match breakpoint in resources/components/patterns/molecules/headroom/_headroom.scss
const breakpoint = 'tiny';

import * as application from './application';

// ////////////////////////////////////////////////////////////
// run bugsnag as early as possible
application.bugsnag();

// ////////////////////////////////////////////////////////////
// check if cookies are enabled
// - add the .no-cookies class to <html /> if not
let cookieEnabled = Boolean(navigator.cookieEnabled);

if (typeof navigator.cookieEnabled === 'undefined' && !cookieEnabled) {
    document.cookie = 'testcookie';
    cookieEnabled = document.cookie.indexOf('testcookie') !== -1;
}

if (!cookieEnabled) {
    document.documentElement.classList.add('no-cookies');
}

// ////////////////////////////////////////////////////////////
// include critical polyfills very early
import * as polyfills from '@spinnwerk/polyfills';

polyfills.focusOptions();

// IE11 polyfills
// polyfills.dom();
// polyfills.fetch();
// polyfills.objectFit();
// polyfills.picturefill();
// polyfills.intersectionObserver();

// ////////////////////////////////////////////////////////////
// image lazy loading
import(/* webpackMode: "lazy" */ 'lazysizes');

// enable support for native lazy loading
/* note: native lazy loading has two major downsides:
 * it loads images much earlier than lazysizes when scrolling down and has no possibility to adjust this value
 * and it loads *all* images above or near the 'fold' regardless of their current visibility state (e.g. in sliders/accordions etc).
 * See https://web.dev/native-lazy-loading#how-does-the-loading-attribute-work-with-images-that-are-in-the-viewport-but-not-immediately-visible-(for-example-behind-a-carousel)
 * If your project is fine with this, feel free to enable the following code and remove the above import:
// import lazySizes from 'lazysizes';
// import 'lazysizes/plugins/native-loading/ls.native-loading';
// lazySizes.cfg.nativeLoading = {
//     setLoadingAttribute: true,
//     disableListeners: true, // remove all event listeners added by lazysizes in browsers that support native lazy loading
// };
 */

// ////////////////////////////////////////////////////////////
// Framework initialization
import * as foundation from './_foundation';

foundation.init();

// ////////////////////////////////////////////////////////////
// Hack vor having full 100vh support on mobile devices
import { fullHeightOnMobile } from './utils/full-height-on-mobile';

fullHeightOnMobile();

// ////////////////////////////////////////////////////////////
// Adds custom classes based on device orientation
import { deviceOrientation } from './utils/device-orientation';

deviceOrientation();

// ////////////////////////////////////////////////////////////
// Security
import { initSecuringExternalAnchors } from './security/external-anchors';

initSecuringExternalAnchors();

// ////////////////////////////////////////////////////////////
// Application setup

// handle service workers
application.serviceWorker({ unregister: true });

// optimized font loading
// fonts are defined in app/etc/.fontsrc.json and automatically added to CONFIG
import { loadFonts } from '../../components/base/fonts/fonts';

loadFonts(CONFIG.fonts);
delete CONFIG.fonts; // release memory, fonts are not needed anymore

// ux / accessibility features
application.handleTabKey();

// init all scrolling belonging
application.autoScrolling({
    fromHashChanges: CONFIG.scrollFromHashChanges,
    offset: {
        small: 0,
        [breakpoint]: {
            up: '.header',
            down: 0,
        },
    },
    getTargetId(hash) {
        return hash.replace(/#\/?/, '');
    },
    beforeScroll(target, $target) {
        /* eslint-disable jquery/no-is, jquery/no-closest */
        // jQuery is required to open targeted Accordion-items anyways, so it is fine to use it */
        if ($target.is('[data-tab-content]')) {
            $target.closest('[data-accordion]').foundation('down', $target);
        }
        /* eslint-enable */
    },
});

// hide elements when others appear
application.clearAway({
    target: '.section-nav',
    trigger: '.footer',
});

// ////////////////////////////////////////////////////////////
// Navigation

// update URL and state links based on scrolling
import { sectionNav } from '../../components/patterns/molecules/navigation';

sectionNav({
    getHashFromElement(el) {
        return '/' + (el.dataset.sectionNavAnchor || el.id);
    },
    getActiveStateSelector(hash) {
        return `[href="#${hash.replace(/^#?\//, '')}"]`;
    },
});

// ////////////////////////////////////////////////////////////
// form helpers
import * as form from '../../components/patterns/molecules/form';

function initFormHelpers() {
    // enable validation
    form.validation().then(() => {
        form.steps(); // validation needs to be in place before multi-step forms can be initialized

        // automatically attach a loading indicator to submit buttons on form submit
        // but make sure, the validation is attached before
        form.loadingIndicator({
            exclude: '.search-control [type="submit"]',
        });
    });

    // enable automatic summaries
    form.summaries();

    // enable conditional inputs
    form.conditionals();

    // enable (de-)selecting all checkboxes at once
    form.selectAll();

    // style advanced file inputs
    form.fileInput({
        multipleFilesLabel: ':anz Dateien ausgewählt',
    });

    // enable multi file uploads
    form.multiUpload();

    // manage hierarchical checkboxes
    form.hierarchicalCheckboxes();

    // manage disabled states of checkboxes and radiobuttons
    form.radioCheckboxes();
}

initFormHelpers();

// WooCommerce replaces DOM completely, so all functionality must be applied again (-> missing event handlers)
document.body.addEventListener(
    'updated_wc_div',
    () => initFormHelpers(),
    polyfills.eventListenerOptions({ passive: true }),
);

// ////////////////////////////////////////////////////////////
// atoms
import { animateSVG } from '../../components/patterns/atoms/animate';

animateSVG();

// ////////////////////////////////////////////////////////////
// molecules
import { scrollUp } from '../../components/patterns/molecules/scroll-up/scroll-up';

scrollUp({
    createButton:
        'createScrollUpButton' in CONFIG ? CONFIG.createScrollUpButton : !document.querySelector('.section-nav'),
});

import { initHeadroom } from '../../components/patterns/molecules/headroom';

application.globalState.set(
    'headroom',
    initHeadroom({
        // add the spacer only if there is no hero element
        prependSpacerTo: document.querySelector('.wrapper .teaser--hero') ? false : '.off-canvas-content',
        breakpoint,
        offset: {
            small: 0,
            [breakpoint]: {
                up: '[data-headroom-offset]',
                down: 0,
            },
        },
    }),
);

import { fontSizeSwitcher } from '../../components/patterns/molecules/font-size-switcher';

fontSizeSwitcher();

import { initPrimaryNav } from '../../components/patterns/molecules/navigation/primary-nav';

initPrimaryNav();

import { callout } from '../../components/patterns/molecules/callout/callout';

callout();

import { cards } from '../../components/patterns/molecules/card/cards';

cards();

import { firstLastClasses } from './utils/first-last-classes';
// the items in the footer navigation and the items in the container needs to be checked separately
firstLastClasses('.footer__small__nav > *');
firstLastClasses('.footer__small__nav .menu__item');
firstLastClasses('.filter__term', '.filter');

import { counter } from '../../components/patterns/molecules/counter';

counter();

import { filters, searches } from '../../components/patterns/molecules/filter';

filters();
searches();

import { lightbox } from '../../components/patterns/molecules/lightbox';

lightbox();

// ////////////////////////////////////////////////////////////
// layouts
import { masonry } from '../../components/layout/masonry';

masonry();

import { swiper } from '../../components/layout/swiper';

swiper();

import { maps } from '../../components/layout/map';

maps();

// ////////////////////////////////////////////////////////////
// browser alerts
import { oldBrowserAlert } from '../../components/patterns/molecules/browser-alerts';

oldBrowserAlert();
// cookieInfoAlert();
// fix a11y flaws of Borlabs Cookie Manager
import { borlabsCookieA11yFixes } from '../../components/patterns/molecules/browser-alerts/borlabs-cookies';

borlabsCookieA11yFixes();

// ////////////////////////////////////////////////////////////
// init web share
import { share } from '../../components/patterns/organisms/share';

share();

// ////////////////////////////////////////////////////////////
// init modals
import { modal } from '../../components/patterns/molecules/modal';

modal();

// ////////////////////////////////////////////////////////////
// login moal open prevent link opening
// eslint-disable-next-line one-var
const loginModal = document.querySelector('[data-open="login-modal"]');

if (loginModal) {
    loginModal.addEventListener('click', (event) => {
        event.preventDefault();
    });
}

// ////////////////////////////////////////////////////////////
// function to add and remove new participants to CF7
document.querySelectorAll('button[data-participant-add]').forEach((button) => {
    button.addEventListener('click', (event) => {
        event.preventDefault();

        const template = button.closest('form').querySelector('template[data-participant-source]'),
            count = button.closest('form').querySelectorAll('fieldset[data-participant]').length + 1;

        if (count > 1) {
            document.querySelectorAll('button[data-participant-remove]').forEach((button) => {
                button.removeAttribute('disabled');
            });
        }

        if (count === 5) {
            document.querySelectorAll('button[data-participant-add]').forEach((button) => {
                button.setAttribute('disabled', '');
            });
        }

        let fieldset = document.createElement('fieldset');

        fieldset.setAttribute('data-participant', '');
        fieldset.removeAttribute('hidden');
        fieldset.innerHTML = template.innerHTML
            .replaceAll('0', count)
            .replaceAll('empty@emtpy.test', '')
            .replaceAll('empty', '');

        button.parentElement.insertAdjacentHTML('beforebegin', fieldset.outerHTML);
    });
});

document.querySelectorAll('button[data-participant-remove]').forEach((button) => {
    button.addEventListener('click', (event) => {
        event.preventDefault();

        let participants = button.closest('form').querySelectorAll('fieldset[data-participant]');

        if (participants.length > 1) {
            participants[participants.length - 1].remove();
        }

        if (participants.length <= 2) {
            document.querySelectorAll('button[data-participant-remove]').forEach((button) => {
                button.setAttribute('disabled', '');
            });
        }

        if (participants.length <= 5) {
            document.querySelectorAll('button[data-participant-add]').forEach((button) => {
                button.removeAttribute('disabled');
            });
        }
    });
});

// ////////////////////////////////////////////////////////////
// function to hide academy billing details & fill inputs with data to prevent validation errors when checkbox is checked
/* eslint-disable one-var */
let academyBillingDetailsActive = document.querySelector('.academy_billing_details_active input'),
    academyBillingAddressItems = document.querySelectorAll('[data-billing-address] > div'),
    academyBillingAddressInputs = document.querySelectorAll('[data-billing-address] input');
/* eslint-enable one-var */

if (academyBillingDetailsActive !== null) {
    academyBillingDetailsActive.addEventListener('click', () => {
        if (academyBillingDetailsActive.checked === true) {
            academyBillingAddressItems.forEach((item, index) => {
                if (index !== 0) {
                    item.style.display = 'none';
                }
            });
            academyBillingAddressInputs.forEach((item, index) => {
                if (index !== 0) {
                    if (item.type === 'email') {
                        item.value = 'empty@emtpy.test';
                    } else {
                        item.value = 'empty';
                    }
                }
            });
        } else {
            academyBillingAddressItems.forEach((item, index) => {
                if (index !== 0) {
                    item.style.display = 'block';
                }
            });
            academyBillingAddressInputs.forEach((item, index) => {
                if (index !== 0) {
                    item.value = '';
                }
            });
        }
    });
}

// ////////////////////////////////////////////////////////////
// Copy contact person data to first particpant (form field value)
/* eslint-disable one-var */
let academyCopyContactPersonDataToFirstParticipant = document.querySelector(
    '.academy_first_participant_copy_data_contact_person input',
);
/* eslint-enable one-var */

if (academyCopyContactPersonDataToFirstParticipant !== null) {
    let academyContactPersonSalutation = document.querySelectorAll('[data-contact-person] input[name="salutation"]'),
        academyContactPersonFirstName = document.querySelector('[data-contact-person] input[name="vname"]'),
        academyContactPersonLastName = document.querySelector('[data-contact-person] input[name="nname"]'),
        academyContactPersonMail = document.querySelector('[data-contact-person] input[name="email"]'),
        academyFirstParticipant = document.querySelectorAll('[data-participant]')[0],
        academyFirstParticipantFirstName = academyFirstParticipant.querySelector(' input[name="p_vname1"]'),
        academyFirstParticipantLastName = academyFirstParticipant.querySelector('input[name="p_nname1"]'),
        academyFirstParticipantMail = academyFirstParticipant.querySelector('input[name="p_email1"]');

    academyCopyContactPersonDataToFirstParticipant.addEventListener('click', () => {
        if (academyCopyContactPersonDataToFirstParticipant.checked === true) {
            academyContactPersonSalutation.forEach((item) => {
                // check which radio input is checked
                if (item.checked === true) {
                    academyFirstParticipant.querySelector(`input[value="${item.value}"]`).checked = true;
                }
            });

            academyFirstParticipantFirstName.value = academyContactPersonFirstName.value;
            academyFirstParticipantLastName.value = academyContactPersonLastName.value;
            academyFirstParticipantMail.value = academyContactPersonMail.value;
        }
    });
}

// ////////////////////////////////////////////////////////////
// function to hide callout for password expiration date when password got changed already (prevent issues with caching)
function hideCalloutForPasswordExpirationWhenCookieIsSet() {
    if (
        document.querySelector('[data-password-expiration-warning]') &&
        document.cookie.split(';').some((item) => item.trim().startsWith('password_reset_successful='))
    ) {
        document.querySelector('[data-password-expiration-warning]').classList.toggle('hide');
    }
}

hideCalloutForPasswordExpirationWhenCookieIsSet();

/* eslint-disable one-var */

// ////////////////////////////////////////////////////////////
// history timeline specific scripts
let historyTimeline = document.querySelector('[data-history-timeline]');

if (historyTimeline) {
    let historyTimelineWrapper = document.querySelectorAll('[data-history-timeline-wrapper]');

    const historyTimelineObserver = new IntersectionObserver(
        (entries, observer) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('history-timeline__wrapper--fade-in');
                    observer.unobserve(entry.target);
                }
            });
        },
        {
            threshold: 0.5,
        },
    );

    historyTimelineWrapper.forEach((el) => {
        historyTimelineObserver.observe(el);
    });
}
